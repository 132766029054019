import {z} from 'zod';

export type JobType = {
  id: number;
  name: string;
};

export type jobTypes = JobType[];

export const GroupedJobType = z.object({
  id: z.number(),
  name: z.string(),
});
export type GroupedJobType = z.infer<typeof GroupedJobType>;
export type GroupedJobTypes = GroupedJobType[];

const JobTypeSchema = z.object({
  id: z.number(),
  name: z.string(),
  category: z.string(),
});

export const JobTypeMasterSchema = z.object({
  jobTypes: z.array(JobTypeSchema),
  q: z.string(),
});
